/**
 * Global error handler!
 * Here to handle the amazing variety of errors we can get.
 */
import { IAddress, IUser } from '@/interface/user.interface'
import { logger } from '@/services'
import jwtDecode from 'jwt-decode'

interface AxiosResponse {
  data: {
    code: number
    message: string
  }
}

interface AxiosErrorResponse {
  status: number
  data: Error
}

interface Error {
  statusCode: string
  message: string
}

function instanceOfAxiosResponse(object: any): object is AxiosResponse {
  return (
    'data' in object &&
    'code' in object.data &&
    'message' in object.data &&
    typeof object.data.code === 'number' &&
    typeof object.data.message === 'string'
  )
}

function getMessages(message: string | string[]): string {
  if (Array.isArray(message)) {
    return message.join(', ')
  }
  return message
}

export const handleError = (
  error: any,
  callback: (message: string, code: string | number) => void,
  message = 'General error',
) => {
  if (error['response'] && instanceOfAxiosResponse(error['response'])) {
    const response = error['response'] as AxiosResponse
    return callback(response.data.message, response.data.code)
  }

  if (error instanceof Error) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const response = error['response'] as AxiosErrorResponse
    if (response) {
      return callback(getMessages(response.data.message), response.status)
    }
    logger.log(error)
    const errorCode = 'code' in error ? error['code'] : '0'

    return callback(error.message ? getMessages(error.message) : message, Number(errorCode))
  }

  if (typeof error === 'object' && error !== null) {
    return callback(
      typeof error['message'] === 'string' ? error['message'] : message,
      typeof error['code'] !== 'undefined' ? error['code'] : 'ErrorObject',
    )
  }

  if (typeof error === 'string') {
    if (error.substring(0, 1) === '{') {
      try {
        const parsed = JSON.parse(error)
        return callback(
          typeof parsed['message'] === 'string' ? parsed['message'] : message,
          typeof parsed['code'] === 'string' || typeof parsed['code'] === 'number'
            ? parsed['code']
            : 'ErrorStringObject',
        )
      } catch (error) {
        return callback(message, 'ErrorJsonParse')
      }
    } else return callback(error, 'ErrorString')
  }

  return callback(message, 'GeneralError')
}

/**
 * Get random avatar color and first letter from string.
 */
export const stringToColor = (input?: string) => {
  let hash = 0
  input = input ?? ''
  for (let i = 0; i < input.length; i++) {
    hash = input.charCodeAt(i) + ((hash << 5) - hash)
  }
  let color = '#'
  let value: number
  for (let i = 0; i < 3; i++) {
    value = (hash >> (i * 8)) & 0xff
    const valueString = '00' + value.toString(16)
    color += valueString.substring(valueString.length - 2)
  }
  return color
}

export const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export const getUserFullName = (user: IUser) => {
  return `${capitalizeFirstLetter(user?.firstName || 'no')} ${capitalizeFirstLetter(user?.lastName || 'name')}`
}

export const getUserRole = (user: IUser) => {
  console.debug(user)
  return '-'
}

function getClenAddress(): IAddress {
  return {
    city: undefined,
    createdAt: undefined,
    location: undefined,
    googleMapLink: '',
    id: 0,
    name: '',
    phone: 0,
    street1: '',
    street2: '',
    type: '',
    updatedAt: undefined,
    zipCode: '',
  }
}

export const getDefaultUserAddress = (addresses?: IAddress[]): IAddress => {
  if (!addresses) return getClenAddress()
  return addresses.length > 0 ? addresses[0] : getClenAddress()
}

export const hasRole = (user: IUser, role: string): boolean => {
  return user.roles.filter((r) => r.name === role).length > 0
}

export const hasPermissions = (permission: string, user?: IUser): boolean => {
  if (!user) {
    try {
      const cUser = getUserByToken(localStorage.getItem('token'))
      if (cUser) return cUser.permissions.filter((r) => r.name === permission).length > 0
      return false
    } catch (e) {
      return false
    }
  }
  return user.permissions.filter((r) => r.name === permission).length > 0
}

export const stringAvatar = (user: IUser) => {
  const name = `${user?.firstName?.charAt(0)}${user?.lastName?.charAt(0)}`
  if (name.length > 0) return name.toUpperCase()
  return 'NU'
}
export const stringAvatarByName = (firstName: string, lastName: string) => {
  const name = `${firstName?.charAt(0)}${lastName?.charAt(0)}`
  if (name.length > 0) return name.toUpperCase()
  return 'NU'
}

export const isExpired = (exp: number): boolean => {
  return new Date().getTime() / 1000 >= exp
}

export const getUserByToken = (token: any): IUser | null => {
  if (!token) return null
  return jwtDecode<IUser>(token)
}

export const scansTypes = (item: string) => {
  if (item === 'bmd') return 'AutoBMD'
  if (item === 'cac') return 'Calcium score'
  if (item === 'nodule') return 'Lung Nodules'
  if (item === 'liver') return 'Fatty Liver'
}
