import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import InfoIcon from '@mui/icons-material/Info'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import { Alert, Box, Button, CardMedia, Paper, Typography } from '@mui/material'
import CssBaseline from '@mui/material/CssBaseline'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Image from 'next/image'
import logo from 'public/images/LogoText.svg'
import hlImage from 'public/images/hl.webp'
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite'
import { ReactNode, useState } from 'react'
import Link from '@/components/shared/link'
import { string } from 'prop-types'

export interface AuthProps {
  email: string
  password: string
  confirmPassword: string
  showPassword: boolean
}

export interface PassProps {
  password: string
  confirmPassword: string
  showPassword: boolean
  showConfirmPassword: boolean
}

export interface IAlertProps {
  type: AlertType
  message: string
}

export interface IInputError {
  message: string
}

export enum AlertType {
  error = 'error',
  warning = 'warning',
  info = 'info',
  success = 'success',
}

export interface AuthLayoutProps {
  busy?: any
  handleSubmit?: any
  buttonName?: any
  children: any
  alert: IAlertProps | null
  title?: string | ReactNode
  description?: string
  showHeader?: boolean
}

export default function AuthLayout({
  children,
  alert,
  title = 'Hi, Welcome!',
  description = 'Please sign in to continue.',
  showHeader = true,
}: AuthLayoutProps) {
  const [isPlayVideo, setPlayVideo] = useState(false)
  const onPlayVideo = () => {
    setPlayVideo(!isPlayVideo)
  }

  return (
    <Grid
      container
      component="main"
      sx={{
        height: '100vh',
        backgroundColor: 'Background.default',
      }}
    >
      <CssBaseline />
      <Grid
        item
        xs={false}
        sm={5}
        md={7}
        sx={{
          height: { xs: '10vh', sm: '100vh' },
          // backgroundImage: 'url(https://source.unsplash.com/random)',
          // backgroundRepeat: 'no-repeat',
          // backgroundColor: (t) =>
          //   t.palette.mode === 'light'
          //     ? t.palette.grey[50]
          //     : t.palette.grey[900],
          // backgroundSize: 'cover',
          // backgroundPosition: 'center',
        }}
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid container direction="column" justifyContent="center" alignItems="left" paddingX={8}>
          <Link href={'https://heartlung.ai'} target={'_blank'}>
            <Image height={80} width={168} src={logo} alt={'logo'} />
          </Link>
          <Grid sx={{ my: 5, display: { xs: 'none', sm: 'block', maxWith: 400 } }}>
            {/*<Typography variant={'h4'} maxWidth={362} >*/}
            {/*  Get screened for heart disease and stroke today.*/}
            {/*</Typography>*/}
            <Typography variant={'body1'} marginY={0} color={'#2B2B2B'}>
              If you are over 40, you are at risk for one of the diseases described in the video. Take preventive
              actions, don't delay!
            </Typography>
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{ width: 'full', display: { xs: 'none', md: 'flex', sm: 'none' } }}
          >
            <Grid
              position={'relative'}
              sx={{
                // border: 1,
                borderRadius: 2,
                color: '#4E4E4E',
                display: 'flex',
                alignItems: 'center',
                overflow: 'hidden',
                justifyContent: 'center',
                width: 800,
                height: 400,
              }}
            >
              {isPlayVideo && (
                <CardMedia
                  controls
                  component="video"
                  title="video"
                  image={'https://livelong-public.s3.us-east-2.amazonaws.com/HeartLungFinal-03-02-2022-FULL-FINAL.mp4'}
                  src={'video'}
                  autoPlay={true}
                  sx={{ borderRadius: 2, position: 'absolute', height: '100%', width: '100%' }}
                />
              )}
              {!isPlayVideo && (
                <>
                  <Image
                    style={{
                      position: 'absolute',
                      objectFit: 'contain',
                      width: '100%',
                      height: '600px',
                    }}
                    src={hlImage}
                    alt={'image'}
                  />
                  <Button onClick={onPlayVideo}>
                    <PlayCircleFilledWhiteIcon
                      sx={{
                        color: 'white',
                        fontSize: 50,
                        '&:hover': {
                          color: 'gray',
                        },
                      }}
                    />
                  </Button>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        sm={7}
        md={5}
        component={Paper}
        elevation={0}
        square
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{ height: { xs: '90vh', sm: '100vh' } }}
      >
        <Box
          sx={{
            width: '100%',
            // maxHeight: 537,
            maxWidth: 480,
            p: 5,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
            sx={{ display: `${showHeader ? 'flex' : 'none'}` }}
          >
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="flex-start"
              sx={{
                mt: 2.5,
              }}
            >
              <Typography variant={'h5'} sx={{ color: 'primary.dark' }}>
                {title}
              </Typography>
              <Typography sx={{ fontSize: 14, fontWeight: 400, color: 'neutral.500' }}>{description}</Typography>
            </Grid>
          </Grid>
          <Box sx={{ width: '100%' }}>
            <Grid sx={{ my: 5, width: '100%' }}>{children}</Grid>
            {alert && (
              <Stack sx={{ width: '100%', marginY: 5 }} spacing={2}>
                <Alert
                  severity={alert.type}
                  iconMapping={{
                    success: <CheckCircleOutlineIcon fontSize="inherit" />,
                    error: <ErrorOutlineIcon fontSize="inherit" />,
                    warning: <WarningAmberIcon fontSize="inherit" />,
                    info: <InfoIcon fontSize="inherit" />,
                  }}
                >
                  {alert.message}
                </Alert>
              </Stack>
            )}
          </Box>
        </Box>
      </Grid>
    </Grid>
  )
}
