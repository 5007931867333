import { useAuthContext } from '@/context/auth-context'
import { getUserByToken, isExpired } from '@/libraries/util'
import { CircularProgress } from '@mui/material'
import Grid from '@mui/material/Grid'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'

export interface UseAuthProps {
  Component: any
  middleware?: string
  redirectIfAuthenticated?: string
}

export const useAuth = ({ Component, middleware = 'auth', redirectIfAuthenticated = '' }: UseAuthProps) => {
  const authComponent = (props: any) => {
    const router = useRouter()
    const { signOut, error } = useAuthContext()
    const [auth, setAuth] = useState<boolean | null>(null)

    useEffect(() => {
      const accessToken = localStorage.getItem('token')
      const user = getUserByToken(accessToken)
      if (user && !isExpired(user.exp) && user.signedIn) {
        console.debug('login YES')
        setAuth(true)
        // updateUserDetails(user)
        // setUser(updateUserDetails(user))
      } else {
        console.debug('login No')
        // if (accessToken) logout().then(() => setAuth(false))
        // else setAuth(false)
        setAuth(false)
      }
    }, [router])

    async function logout() {
      let redirect = ''
      if (router.asPath){
        redirect = `?redirect=${router.asPath}`
      }
      console.debug('logout ....')
      signOut()
      await router.replace(`/${redirect}`)
    }

    useEffect(() => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (error && error.response?.status === 401) {
        logout().then()
      }
    }, [error])

    useEffect(() => {
      console.debug('useEffect auth ---', auth)
      if (auth !== null) {
        if (middleware === 'guest' && auth && redirectIfAuthenticated) router.push(redirectIfAuthenticated).then()
        // if (middleware === 'guest' && !auth) logout().then()
        if (middleware === 'auth' && !auth) logout().then()
      }
    }, [auth])

    if (auth === null) return <Waiting />
    return <Component {...props} />
  }

  authComponent.getLayout = Component.getLayout

  return authComponent
}

export const Waiting = () => (
  <Grid
    container
    spacing={0}
    direction="column"
    alignItems="center"
    justifyContent="center"
    style={{ minHeight: '100vh', backgroundColor: 'transparent' }}
  >
    <CircularProgress />
  </Grid>
)
